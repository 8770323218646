import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';

import { Typography } from '@mui/material';

import Menstrual1 from 'views/ScienceClub/Menstrual';

import MenstrualPhothos from 'views/ScienceClub/MenstrualPhothos';

import SideBarSci from 'views/ScienceClub/SideBarSci';

const Menstrual= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
  
      
  <br/><Typography align='center' variant='h4' fontWeight={800}>
          Science Club </Typography>
        <Container>
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Menstrual1 />
              <MenstrualPhothos/>
            </Grid>
            <Grid item xs={12} md={4}>
           
                <Box marginBottom={4}>
                  <SideBarSci />
                </Box>
           
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
      
    </Main>
  );
};

export default Menstrual;
