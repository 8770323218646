/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Menstrual = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h5' align='center'>
        Menstrual Well-being
        </Typography>
        
        <Typography variant={'subtitle1'} align={'justify'}>
        
        <br/>
        An awareness session on the Menstrual well-being for girls of classes 6 to 8 was organized by the Biology department, National Public School, Yeshwanthpur.
<br/>
<br/>The key objectives of the session were
<br/>* Normalizing conversation on menstruation
<br/>* Understanding the functioning of female reproductive system
<br/>* Awareness on management of menstrual health and hygiene
<br/>* Strategies for effective disposal of sanitary napkins

<br/><br/>Myths and misconceptions associated with menstruation were discussed. The informative session was well received by students, and gave an opportunity to students to share their views and concerns regarding menstruation.
        </Typography>
        <Typography align='center' fontWeight={400}>
        MY WASTE MY RESPONSIBILITY
        </Typography>
        <Box width={1} height={1} marginY={4}>
        </Box>
        
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Menstrual;
